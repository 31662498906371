<template>
	<PaddingContainer
		:component="SectionBlock"
		class="koller-section"
		v-bind="props"
		:padding="padding"
		:use-fallback-container="false"
		:data-centralize-all-above-mobile="
			shouldCentralizeEverythingWhenItIsAboveMobileBreakpoint
		"
	>
		<Component
			:is="component"
			v-for="({ component, props: componentProps }, index) in components"
			:key="component.name || index"
			v-bind="componentProps"
		/>
	</PaddingContainer>
</template>

<script setup lang="ts">
import type {
	KollerSection,
	ScreenSize
} from '@SHARED/core/entities/sections/KollerSection';
import type { FlexOrientation } from '@SHARED/utils/helperTypes';

import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import SectionBlock from '@SHARED/components/blocks/SectionBlock.vue';
import KollerTextSubsection from '@/components/sections/KollerSection/KollerTextSubsection.vue';
import KollerSectionImage from '@/components/sections/KollerSection/KollerSectionImage.vue';

defineOptions({ name: 'KollerSection' });

const props = withDefaults(defineProps<KollerSection['config']>(), {
	shouldCentralizeEverythingWhenItIsAboveMobileBreakpoint: false
});

type SubComponent = {
	component: Component;
	props: Record<string, unknown>;
};

const kollerTextSubsectionComponent = markRaw(KollerTextSubsection);
const kollerSectionImageComponent = markRaw(KollerSectionImage);

const components = computed<[SubComponent, SubComponent]>(() => {
	const image = {
		component: kollerSectionImageComponent,
		props: props.image
	};

	const textSubsection = {
		component: kollerTextSubsectionComponent,
		props
	};

	return [image, textSubsection];
});

function getDirection(screenSize: ScreenSize): FlexOrientation {
	const isReverse = !props.placeImageFirst?.[screenSize];

	const fallbackDirection = screenSize === 'desktop' ? 'row' : 'column';

	const direction = props.contentDirection?.[screenSize] || fallbackDirection;

	return isReverse ? `${direction}-reverse` : direction;
}

const mobileSectionDirection = computed(() => getDirection('mobile'));
const desktopSectionDirection = computed(() => getDirection('desktop'));

const firstGridElementColumn = computed(() =>
	props.placeImageFirst?.desktop ? 1 : 2
);

const secondGridElementColumn = computed(() =>
	props.placeImageFirst?.desktop ? 2 : 1
);

const mobileSectionGap = computed(
	() => props.gapBetweenImageAndText?.mobile || 0
);
const desktopSectionGap = computed(
	() => props.gapBetweenImageAndText?.desktop || 0
);
</script>

<style lang="scss" scoped>
.koller-section {
	display: flex;
	gap: v-bind(mobileSectionGap);
	flex-direction: v-bind(mobileSectionDirection);

	@include screen-up(lg) {
		gap: v-bind(desktopSectionGap);
		flex-direction: v-bind(desktopSectionDirection);
	}
}

[data-centralize-all-above-mobile='true'] {
	@include screen-up(md) {
		padding-left: 0;
		padding-right: 0;
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		width: 80%;

		@supports (width: clamp(500px, 80%, 800px)) {
			width: clamp(500px, 90vw, 1920px);

			@include screen-up(lg) {
				width: clamp(1000px, 60vw, 1920px);
			}
		}

		:nth-child(1) {
			grid-row: 1;
			grid-column: v-bind(firstGridElementColumn);
		}

		:nth-child(2) {
			grid-row: 1;
			grid-column: v-bind(secondGridElementColumn);
		}

		:deep(.image) {
			width: 100%;
		}

		:deep(.koller-text-subsection) {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: auto 0;

			height: 100%;
		}
	}
}
</style>
